body {
    color: #FFFFFF;
    font-family: Roboto, Arial, Helvetica, sans-serif;
}

a {
    color: #FFFFFF; 
}

a:target {
    color: #000000; 
}

h1 {
    color: #FFFFFF;
}